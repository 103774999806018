declare global {
    interface Window {
        FlutterAppCenterAnalytics?: {
            postMessage: (message: string) => void;
        };
        FlutterHapticFeedback?: {
            postMessage: (message: string) => void;
        };
        FlutterGeolocation?: {
            postMessage: (message: string) => void;
        }
        FlutterPdfDocument?: {
            postMessage: (message: string) => void;
        }
    }
}

interface IGeolocationOptions {
    desiredAccuracy?: 'best' | 'bestForNavigation' | 'high' | 'medium' | 'low';
    forceAndroidLocationManager?: boolean;
    timeLimit?: number;
}

interface IGeolocationSuccessResult {
    success: true;
    latitude: number;
    longitude: number;
    accuracy: number;
    altitude: number;
    altitudeAccuracy: number;
    heading: number;
    headingAccuracy: number;
    speed: number;
    speedAccuracy: number;
    timestamp: number;
    floor: number | null;
    isMocked: boolean;
}

interface IGeolocationErrorResult {
    success: false;
    error: string;
}

type IGeolocationResult = IGeolocationSuccessResult | IGeolocationErrorResult;

interface IGeolocationEvent extends Event {
    detail: {
        guid: string;
        result: string;
    }
}

const flutterGeolocationRequests = new Map<string, {
    resolve: (value: IGeolocationResult | PromiseLike<IGeolocationResult>) => void,
    reject: (reason?: any) => void
}>();

document.addEventListener('FlutterGeolocation', function (event: Event) {
    const geolocationEvent = event as IGeolocationEvent;

    const promiseCallbacks = flutterGeolocationRequests.get(geolocationEvent.detail.guid);

    if (promiseCallbacks === undefined) {
        return;
    }

    const geolocationResult = JSON.parse(geolocationEvent.detail.result) as IGeolocationResult;

    promiseCallbacks.resolve(geolocationResult);
});

export function flutterHasAppCenterAnalytics() {
    return window.FlutterAppCenterAnalytics !== undefined;
}

export function flutterSendAppCenterAnalytics(eventName: string, eventProperties: any = {}) {
    window.FlutterAppCenterAnalytics?.postMessage(  
        JSON.stringify({
            "eventName": eventName, 
            "eventProperties": eventProperties,
        })
    );
}

export function flutterHasHapticFeedback() {
    return window.FlutterHapticFeedback !== undefined;
}

// Using this class: https://api.flutter.dev/flutter/services/HapticFeedback-class.html
export function flutterHapticFeedback(hapticFeedbackType: 'HeavyImpact' | 'LightImpact' | 'MediumImpact' | 'SelectionClick' | 'Vibrate') {
    window.FlutterHapticFeedback?.postMessage(hapticFeedbackType);
}

export function flutterHasGeolocation() {
    return window.FlutterGeolocation !== undefined;
}

export function flutterRequestGeolocation(options: IGeolocationOptions = {}): Promise<IGeolocationResult> {
    if (!flutterHasGeolocation()) {
        return Promise.resolve({
            success: false,
            error: 'Flutter JavaScript Channel not found'
        });
    }

    return new Promise((resolve, reject) => {
        const guid = window.crypto.randomUUID();

        flutterGeolocationRequests.set(guid, { resolve, reject });

        window.FlutterGeolocation?.postMessage(JSON.stringify({ guid, ...options }));
    });
}

export function flutterHasPdfDocument() {
    return window.FlutterPdfDocument !== undefined;
}

export function flutterOpenPdfDocument(documentUrl: string) {
    window.FlutterPdfDocument?.postMessage(
        JSON.stringify({
            "documentUrl": documentUrl
        })
    )
}
